.new-transfer {
  padding: 12px;

  .order-item-thumb {
    max-height: 100px;
  }

  .order-item-horiz-rule {
    width: 100%;
    height: 1px;
    background-color: rgb(206, 212, 218);
  }

  .order-item-qty-button-cnt {
    max-width: 180px;
    .order-item-qty-button {
      font-size: 18px;
      font-weight: 800;
    }

    input {
      text-align: center;
    }
  }

  .type-heading {
    color: #1d8c18;
  }

  .no-items {
    padding: 12px;
  }

  .normal-day-alert {
    margin-bottom: 0px;
  }

  .item-title-normal {
    color: #ffc107;
  }

  .item-title-full {
    color: #1d8c18;
  }

  .item-title-short {
    color: #dc3545;
  }
}

.transfer-settings {
  .transfer-settings-card-inner {
    padding: 24px;
  }
}
