.current-orders {
  padding: 24px;

  //h5 {
  //    margin-bottom: 0px;
  //}

  .order-card {
    cursor: pointer;
    &:hover {
      box-shadow:
        rgb(0 0 0 / 11%) 0px 1.2px 3.6px,
        rgb(0 0 0 / 11%) 0px 6.4px 14.4px !important;
    }
  }

  .order-card-inner {
    padding: 24px;
  }

  .total-row {
    padding: 16px;
    width: 100%;
    border-top: 1px solid RGB(221, 226, 235);
  }
}
