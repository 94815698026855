a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.nav-menu {
  background: #1f9419;
  padding-left: calc(1.5rem / 2);
  .nav-menu-app-name {
    margin-bottom: 2px; //balance with uneven logo
    font-size: 1.125rem;
    font-weight: 600;
  }

  .nav-vr {
    width: 2px;
    margin-bottom: 2px;
    opacity: 1;
  }

  .navbar-brand {
    //display: flex;
    width: 100%;
    //.login-dropdown {
    //  margin-left: auto;
    //}
  }
}
