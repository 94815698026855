.missing-orders {
  .btn-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    Button {
      display: flex;
      align-items: center;
    }
  }
}
