.not-found {
  padding: 40px 0;
  background: #fff;
  img {
    width: 100%;
  }
  .background {
    background-image: url("/assets/not-found.gif");
    height: 400px;
    background-position: center;
  }
  .content-box {
    margin-top: -50px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
