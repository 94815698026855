.credit-requests {
  .ag-row-last {
    border-bottom: 0px;
  }

  .btn-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    Button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        margin-right: 3px;
      }
    }
  }
  
  .cr-table {
    .card, .card-header {
      background: transparent;
    }

    .card-header {
      border-bottom: 0px;
      .nav-link.active {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      }
    }

    .card-body {
      border-radius: 3.5px;
    }

  }
}