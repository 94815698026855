.pick-order {
  .fd-checkbox-container,
  .fd-input-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fd-checkbox-lg {
    height: 1.4em;
    width: 1.4em;
  }

  .ag-header-cell.fd-text-center {
    .ag-header-cell-label {
      justify-content: center;
    }
  }

  //.ag-theme-alpine {
  //    --ag-grid-size: 10px;
  //   --ag-list-item-height: 40px;
  //}
}
