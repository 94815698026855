.delete-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px;
  //Remove minimum height from zero row
  .ag-center-cols-clipper {
    min-height: unset !important;
  }
}