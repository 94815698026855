.review-orders {
  //padding: 24px;

  //Make grid compact
  //.ag-theme-alpine {
  //    --ag-grid-size: 3px;
  //    --ag-list-item-height: 20px;
  //}
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .edit-indicator-cell {
    padding: 0px;
  }

  .edit-indicator-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px;
    .ag-header-cell-label {
      justify-content: center;
    }
  }

  .edit-icon-green {
    filter: invert(47%) sepia(88%) saturate(354%) hue-rotate(68deg)
      brightness(101%) contrast(99%);
    //orange
    //filter: invert(50%) sepia(99%) saturate(2424%) hue-rotate(13deg) brightness(110%) contrast(99%);
  }

  .edit-icon-blue {
    filter: invert(22%) sepia(79%) saturate(2371%) hue-rotate(174deg)
      brightness(95%) contrast(101%);
  }

  //Remove extra border on pinned side
  .summary-table-container {
    .ag-pinned-left-cols-container {
      .ag-row-last {
        border-bottom: none;
      }
    }
  }

  .zero-table-container {
    .zero-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0px;
    }
    //Remove minimum height from zero row
    .ag-center-cols-viewport {
      min-height: unset !important;
    }
  }

  .order-title-above-waste {
    color: red;
  }

  .sticky-header {
    .ag-header {
      position: fixed;
      top: 0px;
      z-index: 100;
      background: #fff;
      width: calc(100% - 48px);
    }
  }

  .sticky-scroll {
    .ag-body-horizontal-scroll {
      position: fixed;
      bottom: 0;
      z-index: 100;
      background: #fff;
      width: calc(100% - 48px);
    }
  }
}
