.nav-card {
  cursor: pointer;
  background-color: white;
  & :hover {
    box-shadow:
      rgb(0 0 0 / 11%) 0px 1.2px 3.6px,
      rgb(0 0 0 / 11%) 0px 6.4px 14.4px;
  }

  .nav-card-title {
    color: rgb(50, 49, 48);
    font-size: 16px;
    font-weight: 600;
  }

  a {
    text-decoration: none;
    & :hover {
      box-shadow: none;
    }
  }

  .svg-icon {
    filter: invert(37%) sepia(94%) saturate(430%) hue-rotate(68deg)
      brightness(100%) contrast(100%);
    //orange
    //filter: invert(50%) sepia(99%) saturate(2424%) hue-rotate(13deg) brightness(110%) contrast(99%);
  }
}
