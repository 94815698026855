.order-header {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Stretch to full width */
  margin-bottom: 20px;
}

.order-deliver-to {
  //display: flex;
  font-weight: bold;
  margin-bottom: 10px;
}

.order-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
  width: 100%; /* Take up full width */
}

.order-details-row {
  margin-bottom: 5px;
}

.pick-order-table {
  .ag-body {
    margin-bottom: 16px;
  }
}
