.order-details-table {
  .total-row {
    padding: 16px;
    h5 {
      margin-bottom: 0px;
    }
    width: 100%;
    border-top: 1px solid RGB(221, 226, 235);
  }
  .item-deleted {
    opacity: 0.5;
    .ag-cell {
      text-decoration: line-through;
    }

  }
}
