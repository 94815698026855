.budget-multipliers {
  .ag-row-last {
    border-bottom: 0px;
  }
  .btn-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    Button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        margin-right: 3px;
      }
    }
  }
}
