.store-kpis {
  .ag-header-cell-label {
    justify-content: center;
  }
  .bold-first-column {
    font-weight: 600;
  }
  .page-header {
    margin-bottom: 0px;
  }
  .card-body {
    padding: 32px 32px 32px 32px;
  }
  .selectors {
    padding-bottom: 30px;
  }
  .back-button {
    margin-top: 24px;
  }
}
