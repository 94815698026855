.pick-orders {
  .icon-green {
    filter: invert(47%) sepia(88%) saturate(354%) hue-rotate(68deg)
      brightness(101%) contrast(99%);
  }
  .icon-red {
    filter: invert(16%) sepia(90%) saturate(4501%) hue-rotate(349deg)
      brightness(92%) contrast(103%);
  }
}
