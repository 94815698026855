.unprocessed-orders {
  padding: 24px;

  .order-item-thumb {
    max-height: 100px;
  }

  .order-item-horiz-rule {
    width: 100%;
    height: 1px;
    background-color: rgb(206, 212, 218);
  }

  .order-item-qty-button-cnt {
    max-width: 180px;
    .order-item-qty-button {
      font-size: 18px;
      font-weight: 800;
    }

    input {
      text-align: center;
    }
  }

  .type-heading {
    color: #1d8c18;
  }

  .no-items {
    padding: 12px;
  }

  .btn-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    Button {
      display: flex;
      align-items: center;
      svg {
        margin-right: 3px;
      }
    }
  }
}
