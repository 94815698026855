.store-wastage {
  padding: 24px;

  table {
    margin-bottom: 0px;
  }

  .back-button {
    margin-right: 32px;
  }

  .bold-first-column {
    font-weight: 600;
  }

  .ag-header-group-cell-with-group:not(:last-child) {
    border-right: 1px solid #babfc7;
    justify-content: center;
  }

  .ag-header-group-cell-label {
    justify-content: center;
  }
}
